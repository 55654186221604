

export const formatNumber = (value: number): string => {
    if (value !== undefined) {
        const res = value.toFixed(2);
        return res;
    }

    return "";
};

export function convertNumber(num: string, locale: string) {
    const { format } = new Intl.NumberFormat(locale);
    const [, decimalSign] = /^0(.)1$/.exec(format(0.1))!;
    return +num
        .replace(new RegExp(`[^${decimalSign}\\d]`, 'g'), '')
        .replace(decimalSign, '.');
}